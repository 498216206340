import React from "react"

import Failure from "../../images/Unsuccessful.svg"
import { navigate } from "gatsby";
import { getToken } from "../../services/auth";
import styles from "./styles.module.css"

class NotFound404 extends React.Component {
    constructor(props) {
        super(props);

        const token = getToken();

        this.state = {
            token
        }
    }

    componentDidMount() {
    }

    navigateTo = (toLink) => {
        navigate(toLink);
    }

    render() {
        const successText = 'This page does not exist';
        // const addText1 = 'Follow any links above to learn more about MProfit';
        // const addText2 = 'You can contact us at (022) 4005 7250 or support@mprofit.in if you have any questions or if you would like to schedule a demo.';
        const buttonLink = '/';
        const buttonText = 'Go back to the homepage';

        return (
            <section className={[styles.payment_successfull_section,"plan-grey-background_","container-fluid"].join(" ")}>
                <div className={["container", "h-100"].join(" ")}>
                    <div className={["row", "h-100", "align-items-center"].join(" ")}>
                        <div className="v_center">
                            <div className={[styles.payment_successfull].join(" ")}>
                                <div className={["col", "text-center"].join(" ")}>
                                    <div>
                                        <img src={Failure} alt="Not Found" />
                                    </div>
                                    <h2 className={["mt-5", "pb-3"].join(" ")}>{successText}</h2>
                                    {/* <div className={["mt-2",styles.text2].join(" ")}>{addText1}</div> */}
                                    {/* <div className={["mt-2",styles.text2].join(" ")}>You can give us a call at <a href="tel:+912247492552">(022) 4005 7250</a> or email us at <a href="mailto:MProfit Support<support@mprofit.in>">support@mprofit.in</a> if you have any questions or if you would like to schedule a demo.</div> */}
                                    {
                                        buttonText &&
                                        <div className="">
                                            <button className={["btn", "btn-primary", "rounded", "mt-4"].join(" ")} onClick={() => {this.navigateTo(buttonLink)}}>{buttonText}</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default NotFound404
