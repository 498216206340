import React from "react"

import Layout from "../components/flayout/index"
import NotFound404 from "../components/404";

const NotFoundPage = () => (
  <Layout seoFields={seoFields}>
    <NotFound404/>
  </Layout>
)

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  title: "404: Page Not Found",
  noIndex: true
}

export default NotFoundPage
